import { Link } from "react-router-dom";
import styled from "styled-components";





export const Box = styled.div`
  position: fixed;
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  padding: 0 24px;
  box-sizing: border-box;

  z-index: 1;

  background: #FFFFFF;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  @media (max-width: 600px) {
  position: relative;
  }
`;



export const Image = styled.img`
  width: 100%;
  height: auto;
`;



export const BtnsBox = styled.div`
  width: 372px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 19px;
`;


export const BtnEnterprise = styled(Link)`
  text-decoration: none;
  color: black;
  margin-left: auto;
  cursor: pointer;
`;


export const BtnJoin = styled(Link)`
  height: 35px;
  min-width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-left:25px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
  border: 2px solid black;
  border-radius: 50px;
  text-decoration: none;
  color: black;
  cursor: pointer;

  @media (max-width: 600px) {
  display:none;   
  }
`;
