import Frame1 from '../frames/frame1/Frame1';
import Frame2 from '../frames/frame2/Frame2';
import Frame3 from '../frames/frame3/Frame3';
import Frame4 from '../frames/frame4/Frame4';
import Frame5 from '../frames/frame5/Frame5';
import Frame6 from '../frames/frame6/Frame6';
import * as Style from "./FramesPage.styled";



const FramesPage = () => {

  return (
    <Style.Box>
      <Frame1 />
      <Frame2 />
      <Frame3 />
      <Frame4 />
      <Frame5 />
      <Frame6 />
    </Style.Box>
  )
}


export default FramesPage;