import styled from "styled-components";


export const Box = styled.div<{height: number}>`
  --nav-height: 80px;
  --height: ${props => props.height}px;
  --min-height: calc(var(--height) - var(--nav-height));
  width: 100vw;
  min-height: var(--min-height);
  
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 600px) {
    /* min-height: var(--height); */
    /* margin-top: 0px; */
  }




`;


export const CenterContainer = styled.div`
  width: 1440px;
  max-width: 1440px;
  min-height: var(--min-height);
  display: flex;
  justify-content: center;
  align-items: center;
`;