import { useEffect, useState } from "react";
import * as Styled from "./Frame6.styled"
import FrameBox from "../../components/FrameBox";
import { CenterContainer } from "../../components/FrameBox.styles";
// import FrameBox from "../../components/FrameBox";



const appsUrl = "https://script.google.com/macros/s/AKfycbwnEcbeRoRE77gIflpX50W_GGRAqzc1wFlK6Z-GzGX0xSd7V9DQHJxme0t0u1v_SLW2/exec";

const Frame6 = () => {
    const [success, setSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [message, setMessage] = useState("");


    const [nameError, setNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [companyNameError, setCompanyNameError] = useState(false);

    const handlePhoneNumberChange = (input: string) => {
        const formattedInput = input.replace(/\D/g, '').slice(0, 10);
        setPhone(formattedInput);
    };

    const validateEmail = (email: string) => {
        return email.includes("@") && email.includes(".")
    };

    const validatePhone = (phone: string) => {
        const phoneRegex = /^\d+$/;
        return phoneRegex.test(phone) && phone.length > 8;
    };

    const validateCompanyName = (companyName: string) => {
        return companyName.length > 2;
    };


    const validateName = (name: string) => {
        return name.length > 2;
    };

    useEffect(() => {
        nameError && setNameError(!validateName(name));
        emailError && setEmailError(!validateEmail(email));
        phoneError && setPhoneError(!validatePhone(phone));
        companyNameError && setCompanyNameError(!validateCompanyName(companyName));

    }, [name, email, phone, companyName])


    async function onSubmit(event: { preventDefault: () => void; }) {
        event.preventDefault();

        let isValid = true;

        if (!name) {
            setNameError(true);
            isValid = false;
        }

        if (!email || !validateEmail(email)) {
            setEmailError(true);
            isValid = false;
        }

        if (!phone || !validatePhone(phone)) {
            setPhoneError(true);
            isValid = false;
        }

        if (!companyName) {
            setCompanyNameError(true);
            isValid = false;
        }

        if (isValid) {
            try {
                const response = await fetch(appsUrl, {
                    method: 'POST',
                    body: new URLSearchParams({
                        name: name,
                        email: email,
                        phone: phone,
                        companyName: companyName,
                        message: message
                    })
                });
                setSuccess(true);
                setTimeout(() => setSuccessMessage(true), 1000)
            } catch (error) {

            }
        }
    }

    return (
        <FrameBox>
            <CenterContainer>

                <Styled.Box height={window.innerHeight}>

                    {!successMessage &&
                        <Styled.Container>
                            <Styled.FormBox>
                                <Styled.TitleContainer>
                                    <Styled.Title>Contact us</Styled.Title>
                                    <Styled.TextContainer>
                                        <Styled.Text>How can Mocart bring your 3D vision to life?</Styled.Text>
                                        <Styled.BoldText>We've got answers!</Styled.BoldText>
                                    </Styled.TextContainer>
                                </Styled.TitleContainer>


                                <Styled.FormContainer onSubmit={onSubmit}>
                                    <Styled.InputBox>
                                        <Styled.InputContainer>
                                            <Styled.Input name="name" value={name} onChange={(e) => setName(e.target.value)} placeholder="Your Name*" />
                                            {nameError && <Styled.OopsLabel>oops!</Styled.OopsLabel>}
                                        </Styled.InputContainer>

                                        <Styled.InputContainer>
                                            <Styled.Input name="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Business Email*" />
                                            {emailError && <Styled.OopsLabel>oops!</Styled.OopsLabel>}
                                        </Styled.InputContainer>

                                        <Styled.InputContainer>
                                            <Styled.Input name="phone" value={phone} onChange={(e) => handlePhoneNumberChange(e.target.value)} placeholder="Phone Number*" />
                                            {phoneError && <Styled.OopsLabel>oops!</Styled.OopsLabel>}
                                        </Styled.InputContainer>

                                        <Styled.InputContainer>
                                            <Styled.Input name="companyName" value={companyName} onChange={(e) => setCompanyName(e.target.value)} placeholder="Company Name* " />
                                            {companyNameError && <Styled.OopsLabel>oops!</Styled.OopsLabel>}
                                        </Styled.InputContainer>

                                        <Styled.MessInputBox>
                                            <Styled.MessInput name="message" value={message} onChange={(e) => setMessage(e.target.value)} placeholder="How can we help you?" />
                                        </Styled.MessInputBox>
                                    </Styled.InputBox>

                                    <Styled.BottomBox>
                                        {!success && <Styled.RequiredLabel>*Required</Styled.RequiredLabel>}
                                        <Styled.SubmitBtn issuccess={success ? "true" : "false"}>submit</Styled.SubmitBtn>
                                    </Styled.BottomBox>
                                </Styled.FormContainer>

                            </Styled.FormBox>
                        </Styled.Container>
                    }

                    {successMessage &&
                        <Styled.SuccessBox>
                            <Styled.SuccessContainer>
                                <Styled.Title>Thank You</Styled.Title>
                                <Styled.BoldText>Dear {name}</Styled.BoldText>
                                <Styled.BottomText>One of our team members will reach out to you as soon as possible.</Styled.BottomText>
                            </Styled.SuccessContainer>
                        </Styled.SuccessBox>
                    }
                </Styled.Box>
            </CenterContainer>
        </FrameBox>
    )
}

export default Frame6;