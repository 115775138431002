import styled from "styled-components";



export const Box = styled.div<{height:number}>`
  --nav-height: 80px;
  --height: ${props => props.height}px;
  --min-height: calc(var(--height));
  min-height: var(--min-height);

    position: relative;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    justify-content: center;
    background-color: #FFFFFF;
    margin-top: 100px;
    margin-bottom: 100px;

    @media (max-width: 1300px) {
        margin-bottom: 100px;
    } 
`;



export const Container = styled.div`
    max-height: 828px;
    max-width: 1162px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-items: center;
    border: 2px solid #2C1D87;
    border-radius: 20px;

    @media (max-width: 1300px) {
        width: 1000px;
    } 


    
    @media (max-width: 1100px) {
        max-width: 800px;
    } 

    @media (max-width: 900px) {
        max-width: 500px;
    } 
    
    @media (max-width: 600px) {
        width: 90%;
    } 

`;




export const FormBox = styled.div`
    height: 100%;
    width:90%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
`;




export const TitleContainer = styled.div`
    width: 100%;
    min-width: 272px;
    min-height: 194px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px 0px;
    gap: 32px;
`;


export const Title = styled.span`
    text-align: center;
    font-weight: 800;
    font-size: 58px;
    line-height: 62px;
    margin-bottom: 10px;
    color: #221C47;

    @media (max-width: 700px) {
        font-weight: 700;
        font-size: 48px;
        line-height: 58px;
    }
`;


export const TextContainer = styled.div`
    width: 100%;
    min-height: 66px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;


export const Text = styled.span`
    text-align: center;
    font-size: 24px;
    line-height: 33px;

    @media (max-width: 700px) {
    font-size: 22px;
    line-height: 36px;
    }
`;


export const BoldText = styled.span`
    font-size: 24px;
    line-height: 33px;
    font-weight: 600;

    @media (max-width: 700px) {
    font-size: 22px;
    line-height: 36px;
    }
`;


export const FormContainer = styled.form`
    width: 100%;
    height: 528px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    color: #574F8A;

    @media (max-width: 600px) {
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
    }
`;




export const InputBox = styled.div`
    height: 328px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;

    @media (max-width: 1180px) {
        height: 80%;
        flex-direction: column;
    }
`;

export const InputContainer = styled.div`
    height: fit-content;
    width: 45%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;

    @media (max-width: 1180px) {
        width: 100%;
    }
`;




export const MessInputBox = styled.div`
    height: 20%;
    width: 100%;
    align-items: center;
    justify-content: center;

`;




export const Input = styled.input`
    width: 100%;
    height: 40px;
    font-weight: 400;
    font-size: 18px;
    line-height: 42px;
    color: #574F8A;
    padding: 8px;
    box-sizing: border-box;
    border: none;
    outline: none;
    border-bottom: 1px solid #574F8A;
`;


export const MessInput = styled.input`
    width: 100%;
    height: 65px;
    display: flex;
    font-weight: 400;
    font-size: 18px;
    line-height: 42px;
    padding: 8px;
    box-sizing: border-box;
    color: #574F8A;
    outline: none;
    border: 1px solid #574F8A;
`;


export const OopsLabel = styled.label`
    width: 90%;
    margin-bottom: auto;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: right;
    color: #FF262A;
`;




export const BottomBox = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 8px 8px;
    gap: 24px;
    width: 100%;
    height: 46px;
    box-sizing: border-box;

    @media (max-width: 600px) {
        height: 200px;
        flex-direction: column;
    }
`;


export const RequiredLabel = styled.label`
    align-self: center;
    font-size: 14px;

    @media (max-width: 600px) {
        align-self: self-start;
    }
`;


export const SubmitBtn = styled.button<{issuccess: string}>`
    width: 146px;
    height: 46px;
    margin-left: auto;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    color: #FFFFFF;
    background-color: ${(props) => (props.issuccess === 'true' ? "#9EEFE5" : "#594DED")};

    @media (max-width: 600px) {
        width: 100%;
        margin-left: 0;
    }
`;






export const SuccessBox = styled.div`
    width: 1162px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    color: #574F8A;
    background: #9EEFE5;
    
    @media (max-width: 1200px) {
            width: 95%;
    }
`;


export const SuccessContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px;
    box-sizing: border-box;
`;


export const BottomText = styled.span`
    text-align: center;
    font-size: 24px;
    line-height: 33px;

    @media (max-width: 700px) {
    font-size: 18px;
    line-height: 30px;
    }
`;