import styled from "styled-components";



export const Box = styled.div<{height:number}>`
  --nav-height: 80px;
  --height: ${props => props.height}px;
  --min-height: calc(var(--height) - var(--nav-height));
  min-height: var(--height);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  justify-content: start;
  padding: 32px;
`;





export const TitleContainer = styled.div`
    min-height: 112px;
    width: 100%;
    white-space: normal;
    margin-top: 200px;
    margin-bottom:100px;
    display: flex;
    justify-content: center;
    align-items: center; 
`;


export const Title = styled.div`

font-size: 64px;
  font-weight: 700;
  line-height: 70px;

  @media (max-width: 700px) {
  font-size: 40px;
  line-height: 52px;
  }
`;


export const BottomContainer = styled.div`
    height: 574;
    width: 1328;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    gap: 32px;

  @media (max-width: 900px) {
    /* width: 90%; */
    flex-direction: column;
    } 
`;

export const BottomBox = styled.div`
  height: 574px;
  width: 320px;
  display: flex;
  flex-direction: column;
  justify-items: start;
  box-sizing: border-box;
  border-top: 1px solid #221C47;

  @media (max-width: 1400px) {
    margin: 0 25px;
    width: 400px;
    height: auto;
  } 

  @media (max-width: 700px) {
    width: 100%;
    height: auto;
  } 
`;


export const MediaContainer = styled.div`
  position: relative;
  display: inline-block;
`;


export const Image = styled.img`
   width: 100%;
  height: auto; 
  transition: transform 0.3s ease;
`;

export const Gif = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto; 
  display: none; 


  ${MediaContainer}:hover & {
    display: block;
  }

  @media (max-width: 700px) {
    display: block;
    } 
`;


export const BottomISubject = styled.span`
  font-weight: 700;
  padding: 4% 2% 0% 2%;
  text-align: left;
`;

export const BottomText = styled.span`
  padding: 2%;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
`;


