import styled from "styled-components";



export const Box = styled.div<{height:number}>`
  --nav-height: 80px;
  --height: ${props => props.height}px;
  --min-height: calc(var(--height) - var(--nav-height));
  min-height: var(--height);
    position: relative;
    display: flex;
    justify-items: center;
    align-items: center;
    justify-content: start;
    padding: 32px;
    margin-bottom: 100px;
`;


export const Container = styled.div`
    min-height: 421px;
    width: 100%;
    display: flex;
    flex-direction: column;

    justify-content: center;
    justify-items: center;
    align-items: center;

    @media (max-width: 1300px) {
        margin-top: 200px;
    flex-direction: column;
    height: 100%;
  }

`;




export const TitleContainer = styled.div`
    width: 100%;
    min-height: 177px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 
    padding: 48px 0px;
    gap: 16px;
`;


export const Title = styled.div`

font-size: 64px;
  font-weight: 700;
  line-height: 70px;

  @media (max-width: 700px) {
  font-size: 40px;
  line-height: 52px;
  }
`;




export const BottomContainer = styled.div`
    width: 100%;
    min-height: 428px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    gap: 32px;

    @media (max-width: 767px) {
        flex-direction: column;
        align-items: center ;
    }
`;

export const BottomBox = styled.div`
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 56px 20px 0px;
    gap: 24px;
    width: 30%;
    max-width: 540px;

    border-top: 1px solid #221C47;

    @media (max-width: 767px) {
    width: 100%;
    } 
`;


export const BottomISubject = styled.span`
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    padding-left: 32px;
`;

export const BottomText = styled.span`
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    padding-left: 32px;
    text-align: left;

    @media (max-width: 1000px) {
        font-size: 16px;
        line-height: 26px;
    } 
`;







