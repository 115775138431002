import FrameBox from "../../components/FrameBox";
import { useFrame } from "../../hooks/useFrame";
import * as Styled from "./Frame1.styled";





const Frame1 = () => {
    const { scrollDown } = useFrame();


    return (
        <FrameBox>
            <Styled.Box height={window.innerHeight}>



                <Styled.Container>
                    <Styled.TextContainer>
                        <Styled.BigText>Build 3D web space </Styled.BigText>
                        <Styled.Text>Powerful editor for creating parameter based environments No code, no 3D skills, free, with AI.</Styled.Text>
                    </Styled.TextContainer>

                <Styled.BtnJoin onClick={() => scrollDown()}>Get Started!</Styled.BtnJoin>
                </Styled.Container>

            </Styled.Box>
        </FrameBox>
    )
}

export default Frame1;