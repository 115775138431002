import { useEffect, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import { useFrame } from "../hooks/useFrame";
import * as Styled from "./NavBar.styled"




const NavBar = () => {
  const location = useLocation();
  const [isEnterprise, setIsEnterprise] = useState(false);
  const { scrollDown, scrollTop } = useFrame();

  useEffect(() => {
    setIsEnterprise(location.pathname === '/enterprise');
  }, [location])


  return (
    <Styled.Box>
      <Link to="/" >
        <Styled.Image
          onClick={() => scrollTop()}
          src={"Mocart-logo.svg"}
          alt="mocart logo"
        />
      </Link>

      <Styled.BtnsBox>
        {!isEnterprise &&
          <Styled.BtnEnterprise to="/enterprise">
            Enterprise
          </Styled.BtnEnterprise>
        }
        <Styled.BtnJoin to="/" onClick={() => scrollDown()}>Get Started!</Styled.BtnJoin>
      </Styled.BtnsBox>
      <Outlet />
    </Styled.Box>
  )
}

export default NavBar;