import CheckIcon from '@mui/icons-material/Check';
import { useState } from 'react';
import FrameBox from '../../components/FrameBox';
import { CenterContainer } from '../../components/FrameBox.styles';
import * as Styled from "./Frame5.styled"



const buttonText = [
    "Auto-match brand visual values for seamless integration and instant recognition, while illuminating your brand's atmosphere in 3D spaces",
    "Match textures, geometry and 3D elements to current visual values.",
    "Organize all assets to replicate original website layout into the matched architecture"
]

const Frame5 = () => {

    const [desktopMode,] = useState(window.innerWidth > 600);



    return (
        <FrameBox>
            <CenterContainer>
                <Styled.Box height={window.innerHeight}>


                    <Styled.Container>
                        <Styled.MediaContainer>

                            <Styled.Image
                                src="frame5-img.png"
                                alt="img"
                            />

                            <Styled.IconBox>
                                <img src='./TapIcon.svg' alt='tag icon' />
                                {desktopMode ? 'hover' : 'tap'} to view
                            </Styled.IconBox>

                            <Styled.Gif
                                src="frame5-img.gif"
                                alt="gif"
                            />
                        </Styled.MediaContainer>

                        <Styled.TextContainer>
                            <Styled.BigTextBox>
                                <Styled.Title>
                                Seamless 3D Transition to Maintain Your Brand Identity
                                </Styled.Title>
                            </Styled.BigTextBox>

                            <Styled.TextBox>
                                <Styled.Row>
                                    <Styled.Text>Automatic matching features to ensure a smooth transition,</Styled.Text>
                                </Styled.Row>

                                <Styled.Row>
                                    <Styled.Text>maintaining consistency and familiarity for your audience.</Styled.Text>
                                </Styled.Row>
                            </Styled.TextBox>

                        </Styled.TextContainer>

                    </Styled.Container>

                    <Styled.BottomContainer>
                        {buttonText.map(text => (
                            <Styled.BottomBox key={text}>
                                <Styled.BottomIcon>
                                    <CheckIcon style={{ fontSize: 50 }} />
                                </Styled.BottomIcon>
                                <Styled.BottomText>
                                    {text}
                                </Styled.BottomText>
                            </Styled.BottomBox>
                        ))}
                    </Styled.BottomContainer>

                </Styled.Box>
            </CenterContainer>

        </FrameBox >
    )
}

export default Frame5;