import React, { createContext, RefObject, useContext, useState } from 'react';


interface FrameContextType {
    scrollDown: () => void;
    scrollTop: () => void;
}

export const FrameContext = createContext<FrameContextType>({
    scrollDown: () => {},
    scrollTop: () => {}

});

export const useFrame = () => {
    const context = useContext(FrameContext);
    return context;
};

interface FrameProviderProps {
    children: React.ReactNode;
}



export const FrameProvider: React.FC<FrameProviderProps> = ({ children }) => {

    const scrollDown = () => {
        const currentBoxRef = document.getElementById("page");
        if (currentBoxRef) {
          currentBoxRef.scrollTo({
            top: currentBoxRef.scrollHeight - currentBoxRef.clientHeight - 100,
            behavior: 'smooth',
          });
          return;
        }
        
        else {
          setTimeout(() => scrollDown(), 100);
        }
    }


    const scrollTop = () => {
        const currentBoxRef = document.getElementById("page");
        if (currentBoxRef)
          currentBoxRef.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
      };


    const value = {
        scrollDown,
        scrollTop
    };

    return (
        <FrameContext.Provider value={value}>
            {children}
        </FrameContext.Provider>
    );
};
