import FrameBox from "../../components/FrameBox";
import { CenterContainer } from "../../components/FrameBox.styles";
import * as Styled from "./Frame3.styled";


const buttonText = [
    { subject: "Immersive Browsing Experience", text: "Transform navigation with dynamic 3D environments that engage and intrigue users." },
    { subject: "Stand out", text: "Distinguish your site with innovative 3D spaces that enhance modernity and leave lasting impressions." },
    { subject: "Emotionally Engaging", text: "Build connections with captivating 3D environments that immerse visitors in your business’s unique atmosphere" }
]

const Frame3 = () => {


    return (
        <FrameBox>
            <CenterContainer>

                <Styled.Box height={window.innerHeight}>

                    <Styled.Container>
                        <Styled.TitleContainer>
                            <Styled.Title>3D Web Design Benefits</Styled.Title>
                        </Styled.TitleContainer>

                        <Styled.BottomContainer>
                            {buttonText.map(item => (
                                <Styled.BottomBox key={item.subject}>
                                    <Styled.BottomISubject>
                                        {item.subject}
                                    </Styled.BottomISubject>
                                    <Styled.BottomText>
                                        {item.text}
                                    </Styled.BottomText>
                                </Styled.BottomBox>
                            ))}
                        </Styled.BottomContainer>

                    </Styled.Container>
                </Styled.Box>

            </CenterContainer>
        </FrameBox>
    )
}

export default Frame3;