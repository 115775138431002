import { ReactNode } from "react";
import * as Style from "./FrameBox.styles";



interface FrameBoxProps {
    children: ReactNode;
}

const FrameBox = ({ children }: FrameBoxProps) => {

    return (
        <Style.Box height={window.innerHeight}>
                {children}
        </Style.Box>
    )
}

export default FrameBox;