import styled from "styled-components";



export const Box = styled.div<{height: number}>`
  --nav-height: 80px;
  --height: ${props => props.height}px;
  --min-height: calc(var(--height) - var(--nav-height));
  min-height: var(--height);

  position: relative;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  justify-content: center;
  background-color: #FFFFFF;
  margin-top: 100px;
  padding: 32px;
`;


export const Container = styled.div`
    height: 421px;
    width: 100%;
    display: flex;
    justify-content: start;
    justify-items: center;
    align-items: center;
    gap: 48px;
    
    @media (max-width: 1400px) {
      height: 100%;
      flex-direction: column-reverse;
  }
`;


export const TextContainer = styled.div`
    height: 100%;
    max-width: 609px;
    display: flex;
    flex-direction: column;

    @media (max-width: 1400px) {
    /* max-width: 90%; */
    width: 100%;
  }
`;


export const MediaContainer = styled.div`
  position: relative;
  max-width: 730px;
  box-sizing: border-box;
  display: inline-block;
  border: 5px solid black;
  background-color: aqua;
`;

export const IconBox = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  height: 42px;
  width: 140px;
  bottom: 0px;
  z-index: 1;
  font-size: 46;
  text-align: 56;

  ${MediaContainer}:hover & {
   visibility: hidden;
  }
`;





export const Image = styled.img`
   width: 100%;
  height: auto; 
  margin-bottom: -4px;
  transition: transform 0.3s ease;
`;

export const Gif = styled.img`
  position: absolute;
  height: auto; 
  width: 100%;
  display: none; 
  top: 0;
  left: 0;
  margin-bottom: -4px;

  ${MediaContainer}:hover & {
    display: block;
  }
`;


export const Title = styled.div`
  font-size: 64px;
  font-weight: 700;
  line-height: 70px;
  word-wrap: break-word;

  @media (max-width: 700px) {
  font-size: 40px;
  line-height: 52px;
    } 
`;


export const Row = styled.span`
    white-space: nowrap;
`;


export const BigTextBox = styled.div`
  max-width: 609px;
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    align-items: start;
`;


export const BigText = styled.span`
  font-weight: 500;
  font-size: 72px;
  line-height: 81px;

  @media (max-width: 700px) {
    font-weight: 500;
    font-size: 42px;
    line-height: 54px;
  }
`;


export const Text = styled.span`
  font-size: 20px;
  font-weight: 400;

  @media (max-width: 700px) {
    white-space: normal;
  }
`;

export const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`;


export const BottomContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 50px;
  @media (max-width: 767px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 80px;
  }
`;


export const BottomBox = styled.div`
  width: 30%;
  display: flex;
  flex-direction: row;
  border-top: 1px solid black;

  @media (max-width: 767px) {
      width: 100%;
  }
`;


export const BottomText = styled.span`
  padding: 2%;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;

  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 24px;
  }
`;


export const BottomIcon = styled.span`
  min-width: 50px;
  max-width: 100px;
`;





