import NavBar from "../../navBar/NavBar";
import { useFrame } from "../../hooks/useFrame";
import * as Style from "./Enterprise.styled";



const Enterprise = () => {
    const { scrollDown } = useFrame();
    return (
        <>
            <NavBar />
            <Style.Box height={window.innerHeight} width={window.innerWidth}>
                <Style.TopBox>
                    <Style.TopContainer>
                        <Style.BigText>Boost your organization relevance</Style.BigText>
                        <Style.Text>by offering a custom made 3d website</Style.Text>
                        <Style.SmallText>Collaborate with our team of experts to create immersive 3D experiences customized to your organization's needs. Enhance engagement and stand out digitally with experiences that reflect your brand's identity and commitment to innovation.</Style.SmallText>

                        <Style.BtnLetsTalk to="/" onClick={() => scrollDown()}>Talk to us!</Style.BtnLetsTalk>

                    </Style.TopContainer>
                </Style.TopBox>

                <Style.companysBox>
                    <Style.TechSlideshow>
                        <Style.Mover1 />
                        {/* <Style.Mover2/> */}
                    </Style.TechSlideshow>
                </Style.companysBox>

                <Style.BottomBox>
                    <Style.BottomTitleBox>
                        <Style.BottomTitle>a taste of a new generation digital experience for your organization</Style.BottomTitle>
                    </Style.BottomTitleBox>
                    <Style.BottomBtnsBox>
                        <Style.BottomBtn> 3D WEBSITE</Style.BottomBtn>
                        <Style.BottomBtn> AR</Style.BottomBtn>
                        <Style.BottomBtn> VR</Style.BottomBtn>

                    </Style.BottomBtnsBox>
                    <Style.BottomTextBox>

                        <Style.BottomText>By opening doors to immersive experiences,
                            we allow users to navigate through virtual environments,
                            explore products in 3D, and engage with content like never before.
                        </Style.BottomText>
                    </Style.BottomTextBox>
                </Style.BottomBox>
            </Style.Box>
        </>
    )
}


export default Enterprise;