import NavBar from "../navBar/NavBar";
import FramesPage from "../components/FramesPage";
import * as Styled from "./HomePage.styled";







const HomePage = () => {



    return (
        <Styled.Box id="page">
            <NavBar />
            <FramesPage/>
        </Styled.Box>
    )
}

export default HomePage;