import styled from "styled-components";

export const Box = styled.div<{height: number}>`
    position: relative;
  --nav-height: 80px;
  --height: ${props => props.height}px;
  --min-height: calc(var(--height) - var(--nav-height));
    min-width: 100vw;
    height: var(--height);

    max-height: var(--min-height);
    margin-top: var(--nav-height);
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    align-items: center;
    color: white;
    background-color: #221c47;
    @media (max-width: 600px) {
    margin-top: 0px;
  }

`;



export const Container = styled.div`
display: flex;
flex-direction: column;
justify-content: space-between;
align-items: center;
gap: 32px;

`;


export const TextContainer = styled.div`
    width: 877px;
    height: 185px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (max-width: 1000px) {
        width: 80%;
  }
`;


export const BigText = styled.span`
    text-align: center;
    font-size: 64px;
    line-height: 73px;
    letter-spacing: 2.56;
    text-transform: uppercase;

    @media (max-width: 1000px) {
        font-size: 48px;
        line-height: 57px;
  }

  @media (max-width: 700px) {
        font-size: 32px;
        line-height: 42px;
  }
`


export const Text = styled.div`
    font-size: 30px;
    font-weight: 100;
    padding: 10px;
    text-align: center;
    font-weight: 400;
    word-wrap: break-word;


    @media (max-width: 1000px) {
        font-size: 24px;
        line-height: 34px;
  }

    @media (max-width: 700px) {
        font-size: 18px;
        line-height: 26px;
  }
`;


export const BtnJoin = styled.div`
    display: flex;
    width: 244px;
    height: 54px;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    font-size: 24px;
    border-radius: 50px;
    background: #594DED;
    cursor: pointer;
    @media (max-width: 600px) {
        width: 90%;
    }
`;






