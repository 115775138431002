import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; 
import { FrameProvider } from './hooks/useFrame';
import HomePage from './pages/HomePage';
import Enterprise from './pages/enterprise/Enterprise';
import './App.css';

function App() {
  return (
    <Router> 
      <FrameProvider>
        <Routes>
          <Route path='/' element={<HomePage />} />
          <Route path='/enterprise' element={<Enterprise />} />
        </Routes>
      </FrameProvider>
    </Router>
  );
}

export default App;
