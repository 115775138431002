import FrameBox from "../../components/FrameBox";
import { CenterContainer } from "../../components/FrameBox.styles";
import * as Styled from "./Frame2.styled";



const buttonText = [
    { subject: "Portfolio", text: "Showcase your portfolio with dynamic 3D visuals, highlighting your innovative approach", gif: "Portfolio2" },
    { subject: "Blog", text: "Engage a devoted audience with immersive 3D content that brings stories to life.", gif: "Blog1" },
    { subject: "eCommerce", text: "Revolutionize shopping with interactive 3D product displays and virtual storefronts.", gif: "eCommerce1" },
    { subject: "Appointment Platforms", text: "Attract clients with easy 3D booking interfaces capturing your business's unique vibe like never before.", gif: "aapintment" }
]

const Frame2 = () => {

    return (
        <FrameBox>
            <CenterContainer>

                <Styled.Box height={window.innerHeight}>

                    <Styled.TitleContainer>
                        <Styled.Title> Discover New Dimensions for Your Business</Styled.Title>
                    </Styled.TitleContainer>

                    <Styled.BottomContainer>
                        {buttonText.map(item => (
                            <Styled.BottomBox key={item.subject}>
                                <Styled.MediaContainer>
                                    <Styled.Image src={item.gif + ".jpeg"} alt=" Image" />
                                    <Styled.Gif src={item.gif + ".gif"} alt=" GIF" />
                                </Styled.MediaContainer>
                                <Styled.BottomISubject>
                                    {item.subject}
                                </Styled.BottomISubject>
                                <Styled.BottomText>
                                    {item.text}
                                </Styled.BottomText>
                            </Styled.BottomBox>
                        ))}
                    </Styled.BottomContainer>

                </Styled.Box>
            </CenterContainer>
        </FrameBox>
    )
}

export default Frame2;