import { Link } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';



export const linkStyle = {
  textDecoration: "none",
  with: "100%",
};


export const Box = styled.div<{height:number, width:number}>`
    position: absolute;
    --nav-height: 80px;
    --height: ${props => props.height}px;
    --width: ${props => props.width}px;

  --min-height: calc(var(--height) - var(--nav-height));

  
  width: 100%;
  height: var(--min-height);
  /* margin-top: var(--nav-height); */
  
    /* max-height: 300px; */
    top: var(--nav-height);
    overflow-y: auto;

    display: flex;
    flex-direction: column;
    /* justify-content: flex-start; */
    justify-items: center;
    align-items: center;
    color: white;
    background-color: #221c47;



  background: #221C47;
`;




export const TopBox = styled.div`
display: flex;
justify-content: center;
align-items: center;

width: 1440px;
min-height: 754px;
/* background-color: pink; */

@media (max-width: 1000px) {
      width: 760px;

  }



  @media (max-width: 800px) {
      width: 90%;

  }

`;


export const TopContainer = styled.div`
display: flex;
flex-direction: column;
justify-content: start;
align-items: start;
/* padding: 105px 74px; */
gap: 8px;

max-width: 960px;
min-height: 439px;
/* min-height: 2200px; */
/* background-color: darkcyan; */



`;


export const BigText = styled.span`
max-width: 912px;
/* height: 134px; */
/* height: 100%; */

font-weight: 600;
font-size: 56px;
line-height: 67px;
/* or 120% */
display: flex;
align-items: center;
letter-spacing: 0.08em;
text-transform: uppercase;

color: #FFFFFF;



/* font-size: 64px;
    line-height: 73px;
    text-transform: uppercase; */

    @media (max-width: 1000px) {
        /* font-size: 48px;
        line-height: 57px; */
  }

  @media (max-width: 600px) {
        font-size: 48px;
        line-height: 56px;
  }

  @media (max-width: 500px) {
        font-size: 40px;
        line-height: 48px;
  }


  @media (max-width: 400px) {
        font-size: 32px;
        line-height: 38px;
  }
`;







export const Text = styled.span`
max-width: 960px;
/* height: 168; */

font-weight: 500;
font-size: 32px;
line-height: 73px;
text-transform: capitalize;

color: #FFFFFF;
/* background-color: aquamarine; */

@media (max-width: 700px) {
        font-size: 28px;
        line-height: 38px;
  }

  @media (max-width: 600px) {
        font-size: 22px;
        line-height: 32px;
  }

  @media (max-width: 400px) {
        font-size: 18px;
        line-height: 26px;
  }

`;



export const SmallText = styled.span`
max-width: 722px;
height: 168px;

font-weight: 400;
font-size: 24px;
line-height: 32px;

color: #FFFFFF;

@media (max-width: 700px) {
        font-size: 20px;
        line-height: 28px;
  }





`;




export const BtnLetsTalk = styled(Link)`
display: flex;

width: 244px;
height: 56px;

border-radius: 50px;

font-weight: 500;
font-size: 20px;
line-height: 52px;
justify-content: center;
text-align: center;
text-transform: capitalize;
text-decoration: none;
color: #FFFFFF;
background: #594DED;
cursor: pointer;

@media (max-width: 700px) {

  width: 100%;
height: 56px;
        /* font-size: 22px;
        line-height: 32px; */
  }

`;


export const companysBox = styled.div`
/* padding: 105px 74px; */
position: relative;
display: flex;
align-items: center;

width: 100%;
height: 206px;
min-height: 100px;

/* background-color: purple; */

`;



const moveSlideshow = keyframes`
  100% { 
    transform: translateX(-66.6666%);  
  }
`;

const SlideshowItem = styled.div`
  position: absolute;
  height: 126px;
  width: 6526px;
  top: 0;
  left: 0;
  background: url(./companysLogo.svg);
  /* background-size: cover; */

`;

export const TechSlideshow = styled.div`
  position: relative;
  min-height: 126px;
  /* max-width: 900px; */
  max-width: var(--width);
  width: 100%;
  /* margin: 0 auto; */
  overflow: hidden;
`;



export const Mover1 = styled(SlideshowItem)`
  animation: ${moveSlideshow} 60s linear infinite;
`;




export const BottomBox = styled.div`
display: flex;
flex-direction: column;
align-items: center;
padding: 105px 74px;
gap: 8px;

width: 1440px;
/* height: 100%; */
min-height: 560px;
/* min-height: 2200px; */
/* background-color: cadetblue; */

@media (max-width: 1100px) {
  min-height: 1060px;
      width: 760px;
      gap: 112px;
  }



  @media (max-width: 800px) {
      width: 90%;


  }

`;


export const BottomTitleBox = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
/* padding: 32px 0px; */
gap: 8px;

max-width: 1056px;
/* height: 184px; */
height: 100%;


`;


export const BottomTitle = styled.span`
max-width: 1056px;
/* height: 120px; */
height: 100%;

font-weight: 600;
font-size: 56px;
line-height: 60px;
/* or 107% */
display: flex;
align-items: center;
/* text-align: center; */
/* text-transform: capitalize; */
color: #FFFFFF;




/* background-color: cornflowerblue; */
@media (max-width: 700px) {
        font-size: 48px;
        line-height: 56px;
  }

  @media (max-width: 600px) {
        font-size: 42px;
        line-height: 50px;
  }

  @media (max-width: 400px) {
        font-size: 38px;
        line-height: 46px;
  }

`;





export const BottomBtnsBox = styled.div`
display: flex;
justify-content: center;
align-items: center;
padding: 0px;
gap: 32px;

max-width: 1056px;
/* height: 88px; */
height: 100%;
/* background-color: cornflowerblue; */

@media (max-width: 1100px) {
  /* height: 136px; */
  width: 100%;
  flex-direction: column;


  }

`;



export const BottomBtn = styled.div`
box-sizing: border-box;

display: flex;
justify-content: center;
align-items: center;
padding: 0px;
gap: 8px;

width: 330.67px;
height: 88px;
border: 2px solid rgba(255, 255, 255, 0.5);
/* border: 2px solid #FFFFFF; */
border-radius: 20px;
/* opacity: 0.5; */
@media (max-width: 1100px) {
  width: 100%;
  height: 136px;
  }
`;








export const BottomTextBox = styled.div`
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 16px 0px;
gap: 8px;

max-width: 1056px;
min-height: 224px;

/* background-color: crimson; */

`;



export const BottomText = styled.span`
max-width: 1056px;
min-height: 192px;

font-weight: 400;
font-size: 32px;
line-height: 48px;
/* or 150% */
display: flex;
align-items: center;
/* text-align: center; */

color: #FFFFFF;

/* background-color: cornflowerblue; */
@media (max-width: 700px) {
        font-size: 20px;
        line-height: 28px;
  }

  @media (max-width: 600px) {
        /* font-size: 42px;
        line-height: 50px; */
  }

  @media (max-width: 400px) {
        /* font-size: 38px;
        line-height: 46px; */
  }

`;









